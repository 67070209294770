import { combineReducers } from 'redux';
import {
  FETCHING_RECENTLY_VIEWED_PRODUCTS,
  SUCCESS_FETCHING_RECENTLY_VIEWED_PRODUCTS,
  ERROR_FETCHING_RECENTLY_VIEWED_PRODUCTS
} from './recentlyViewedCarouselConstants';
import { EMPTY_CART } from '../../cart/components/emptyCart/emptyCartConstants';
import { RECEIVE_CART_ITEMS_SUCCESSFULLY } from '../../cart/components/cartList/cartListConstants';
import { emptyCartTemplate } from '../../cart/components/emptyCart/emptyCartReducer';

const recentlyViewedReducerDefault = {
  products: [],
  status: 'success',
  shouldRender: true,
  carouselClass: 'recentlyViewCarousel',
};

// We need to connect the cart app state with recentlyViewCarousel specially when is empty.
// We have problems to share the live state between this 2 components because of the actual
// app architecture so we had to create a workaround to share the state out of react using
// javascript listeners.

export const hideCarousel = () => {
  const event = new Event('hideCarousel');
  document.body.dispatchEvent(event);
};

export const showCarousel = () => {
  const event = new Event('showCarousel');
  document.body.dispatchEvent(event);
};

const processDataForProducts = (products) => {
  const newProducts = [];
  products.forEach((product) => {
    const heroImage = product.images.find(image => image.imageType === 'PRIMARY') || {};
    const altImage = product.images.find(image => image.imageType === 'ALTERNATIVE') || {};
    const wasPrice = (product.wasPrice) ? product.wasPrice.value : 0;
    const Price = (product.price) ? product.price.value : 0;
    newProducts.push({
      ...product,
      altImage: altImage.url || null,
      heroImage: heroImage.url || null,
      heroBadges: [],
      promoBadges: [],
      markdown: Price < wasPrice,
    });
  });
  return newProducts;
};

const getProducts = (state = recentlyViewedReducerDefault, action) => {
  const { status, products } = action;

  switch (action.type) {
    case FETCHING_RECENTLY_VIEWED_PRODUCTS:
      return { ...state, status };

    case ERROR_FETCHING_RECENTLY_VIEWED_PRODUCTS:
      return { ...state, status };

    case SUCCESS_FETCHING_RECENTLY_VIEWED_PRODUCTS:
      return { ...state, status, products: processDataForProducts(products) };
    case EMPTY_CART:
      showCarousel();
      return { ...state, shouldRender: true };
    case RECEIVE_CART_ITEMS_SUCCESSFULLY:
      hideCarousel();
      return { ...state, shouldRender: false };
    default:
      return state;
  }
};

export default combineReducers({
  getProducts,
  emptyCartTemplate,
});
