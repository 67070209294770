import { GET_INSTRUCTIVE_URL, GET_RECIPE_BOOK_URL } from './productExtraDetailsConstants';


const getsocialNetworkInstructiveInfo = (state = {}, action) => {
  const { url, recipeBookUrl } = action;
  switch (action.type) {
    case GET_INSTRUCTIVE_URL:
      return { ...state, url };
    case GET_RECIPE_BOOK_URL:
          return { ...state, recipeBookUrl };
    default:
      return state;
  }
};

export default getsocialNetworkInstructiveInfo;
