import {
  FETCHING_RECOMMENDED_PRODUCTS,
  SUCCESS_FETCHING_RECOMMENDED_PRODUCTS,
  ERROR_FETCHING_RECOMMENDED_PRODUCTS,
} from './recommendedProductsCarouselConstants';

import { EMPTY_CART } from '../../../orderPlacement/cart/components/emptyCart/emptyCartConstants';

const recommendedReducerDefault = {
  products: [],
  status: 'success',
  emptyCart: false,
  updated: false
};

const processDataForProducts = (products) => {
  const newProducts = [];
  products.forEach((product) => {
    const heroImage = product.images[0];
    const altImage = product.images[1];

    newProducts.push({
      ...product,
      altImage: altImage || null,
      heroImage: heroImage || null,
      heroBadges: [],
      promoBadges: []
    });
  });
  return newProducts;
};

const getProducts = (state = recommendedReducerDefault, action) => {
  const { status, products } = action;

  switch (action.type) {
    case FETCHING_RECOMMENDED_PRODUCTS:
      return { ...state, status };

    case ERROR_FETCHING_RECOMMENDED_PRODUCTS:
      return { ...state, status };

    case SUCCESS_FETCHING_RECOMMENDED_PRODUCTS:
      return { ...state, status, products: processDataForProducts(products) };

    case EMPTY_CART:
      return { ...state, emptyCart: true, updated: true };

    default:
      return state;
  }
};



export default getProducts;
