import React from 'react';
import { render } from 'react-dom';
import store from 'app/orderPlacement/orderPlacementStore';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import MiniCartApp from './miniCartApp';

const MiniCartHOC = () => (
  <MiniCartApp />
);

const MiniCartRoot = providerHOC(store)(MiniCartHOC);
render(<MiniCartRoot />, document.getElementById('mini-cart'));
